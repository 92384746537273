import { useLanguage, useLayout } from '@/hooks/Contexts'
import { API_URL } from '@/utils/Consts'
import { Competition } from '@models/Index'
import { appUtils } from 'lib/AppUtils'
import Link from 'next/link'
import { FC, useEffect, useState } from 'react'
import CompetitionItem from './CompetitionItem'
import CompetitionSlider from './CompetitionSlider'

export const CompetitionsEndSoon: FC<{ extraCls?: string }> = ({ extraCls }) => {
  const { categories: cateStr } = useLanguage()
  const { isDesktop, breakpoint } = useLayout()
  const [data, setData] = useState<Competition[]>([])
  useEffect(() => {
    if (typeof isDesktop != 'undefined') {
      appUtils.fetcher(`${API_URL.recommend}?store_id=1&perPage=${isDesktop ? 4 : 6}`, false).then(res => {
        setData(res)
      })
    }
  }, [isDesktop])
  return (
    <>
      {data?.length > 0 ? (
        <div className={`${isDesktop ? 'fluid-gray' : 'container-white'} ${extraCls || ''}`}>
          <div className={`mb-4 flex w-full items-center justify-between text-lg${isDesktop ? ' container-gray' : ''}`}>
            <div className='md:text-2xl'>
              <span className='font-semibold text-amber-v1'>{cateStr.comp_end_soon[0]}</span>
              {cateStr.comp_end_soon[1]}
            </div>
            <Link href='/competitions' passHref>
              <a className='clickable-text font-semibold'>{cateStr.view_all}</a>
            </Link>
          </div>
          {!breakpoint ? null : breakpoint.includes('xl') ? (
            <div className='container-gray grid animate-fade-in-down grid-cols-4 gap-4'>
              {data.map((item, index) => (
                <CompetitionItem key={item.id} competition={item} isFirst={index == 0} />
              ))}
            </div>
          ) : (
            <CompetitionSlider items={data} />
          )}
        </div>
      ) : null}
    </>
  )
}
export default CompetitionsEndSoon
